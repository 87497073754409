<template>
  <GameLayout>
    <!-- Content -->
    <template #content>
      <div class="w-full h-5/6 flex border-2 border-space-green rounded-xl px-6 py-6">
        <div class="w-full pr-10">
          <h3 class="geminis text-space-green text-xl">
            Corrige le code
          </h3>
          <p class="text-sm my-5">
            {{ currentExercise.gameExercises.instruction }}
          </p>
          <div class="mb-6 text-sm">
            <span>Progression: {{ completedExercises }}/{{ startedExercises.length }}</span>
            <div class="w-full bg-white rounded-full h-4 mb-4 mt-1">
              <div
                class="bg-space-green h-4 rounded-full"
                :style="'width: ' + (completedExercises / startedExercises.length) * 100 + '%'"
              />
            </div>
          </div>
          <div
            id="editor-1"
            class="h-2/6 lg:h-3/6 2xl:h-4/6 overflow-y-hidden"
            @click="onIdeClick"
          />
          <div class="w-full flex flex-col mt-2">
            <div class="w-full bg-space-dark h-20 px-4 py-2">
              <span>Console de sortie :</span><br>
              <span
                class="output-result"
                :class="output && output.isError ? 'text-red-500' : 'text-white'"
              >{{ output ? output.output : '' }}</span>
            </div>
            <div class="w-full flex justify-between mt-2">
              <div
                class="text-center !h-fit bg-white rounded-full font-bold px-4 py-1 text-space-dark-blue shadow-space w-3/5 xl:w-1/5 mt-2 ml-2 cursor-pointer"
                @click="executeCode"
              >
                TESTER
              </div>
              <ExerciseClues class="w-1/2" v-if="currentExercise.gameExercises && currentExercise.gameExercises.clues && currentExercise.gameExercises.clues.length" :clues="currentExercise.gameExercises.clues || []" />
            </div>

          </div>
        </div>
      </div>
    </template>
  </GameLayout>
</template>

<script>
import GameLayout from '@/layouts/GameLayout';
import loader from "@monaco-editor/loader";
import GameService from "@/services/gameService";
import MonacoService from "../../services/monacoService";
import router, {ROUTE_ROOM_WIN} from "@/router";
import {store} from "@/store/store";
import {UPDATE_ROOM_STATE} from "@/store/modules/userModule/actions";
import {GAME_OVER} from "@/constants/games/GameStatusConstants";
import ExerciseClues from "@/components/Games/ExerciseClues.vue";

export default {
  name: 'ErrorsMulti',
  components: {
    ExerciseClues,
    GameLayout,
  },
  props: {
    user: Object,
    room: Object,
  },
  data() {
    return {
      /** General */
      language: 'javascript',
      userEditor: null,
      output: null,
      loading: false,
      timeout: null,
      monaco: loader.init(),

      /** Game */
      team: null,
      startedExercises: [],
      currentExercise: null,

      /** Variables pour le partage des IDEs */
      userRemoteCursorManager: null,
      userEditorContentManager: null,
    };
  },
  computed: {
    completedExercises() {
      return GameService.exercisesCompleted(this.startedExercises);
    }
  },
  watch: {
    currentExercise() {
      this.userEditor?.getModel().setValue(this.currentExercise ? this.currentExercise.code : '');
    },
    startedExercises() {
      if (!GameService.getNextNotSolvedExercise(this.startedExercises)) {
        store.dispatch(UPDATE_ROOM_STATE, GAME_OVER);
        router.push({ name: ROUTE_ROOM_WIN.name, params: {pin: this.$route.params.pin} });
      }
    },
  },
  async mounted() {
    document.title = 'Corrige le code | SpaceCode';

    // Create Teams
    const teams = await GameService.getTeamsFromGameId(this.room.game.id);
    this.team = teams.find(team => team.id === this.user.team.id);

    // Get exercises
    this.startedExercises = await GameService.getStartedExercicesTeamId(this.team.id, false);

    // Next not solved exercise
    this.currentExercise = GameService.getNextNotSolvedExercise(this.startedExercises);

    // Init Monaco
    this.monaco = await loader.init();

    // Create editors
    this.userEditor = this.monaco.editor.create(document.getElementById("editor-1"), {
      value: this.currentExercise.code,
      language: this.language,
      theme: 'vs-dark',
      minimap: {enabled: false}
    });

    // Cursor management
    this.userRemoteCursorManager = MonacoService.createRemoteCursorManager(this.userEditor);

    // Create cursors
    this.team.users.forEach(user => {
      // Don't create for this user
      if (user.id === this.user.id) {
        return;
      }
      // Add cursor
      const newCursor = this.userRemoteCursorManager.addCursor(
        user.id.toString(),
        this.getRandomColor(),
        user.pseudo
      );

      // Add cursor to the user
      this.room.users.find(u => u.id === user.id ).cursor = newCursor;

      newCursor.setPosition({column: 3, lineNumber: 3});
      newCursor.show();
    });

    // Editor content managers
    this.userEditorContentManager = MonacoService.createRemoteContentManager(
      this.userEditor,
      this.$socket,
      this.team.id,
      this.$route.params.pin
    );

    // IDE on key up
    this.userEditor.onKeyUp((event) => MonacoService.onKeyUpListener(
      event,
      this.$socket,
      this.userEditor,
      this.$route.params.pin,
      this.team.id,
      this.user
    ));
  },
  methods: {
    async executeCode() {
      // Inform the other client that a validation is happening
      // this.$socket.client.emit('validating', {
      //   pin: this.$route.params.pin,
      //   validating: true
      // });

      if (!this.loading) {
        // Validate and save
        const res = await GameService.validateExercise(this.currentExercise, this.userEditor.getModel().getValue());

        this.output = {
          output: res.error ?? res.output,
          isError: !!res.error,
        };

        if (!res.error) {
          this.loading = true;

          // Next exercise
          setTimeout(async () => {
            this.$socket.client.emit('nextExercise', {
              pin: this.$route.params.pin,
              team_id: this.team.id,
            });
            this.startedExercises = await GameService.getStartedExercicesTeamId(this.team.id, false);
            this.currentExercise = GameService.getNextNotSolvedExercise(this.startedExercises);
            this.output = null;
            this.loading = false;
          }, 1500);
        }
      }
    },

    onIdeClick() {
      MonacoService.onIdeClick(this.$socket, this.user, this.userEditor, this.$route.params.pin);
    },

    getRandomColor() {
      return '#' + Math.floor(Math.random()*16777215).toString(16);
    }
  },

  sockets: {
    validating(value) {
      this.loading = value;
    },

    async nextExercise() {
      this.startedExercises = await GameService.getStartedExercicesTeamId(this.team.id, false);
      this.currentExercise = GameService.getNextNotSolvedExercise(this.startedExercises);
    },

    /**
		 * IDE handlers
		 */
    userCursorChange(params) {
      const user = this.room.users.find(user => user.id === params.user.id);
      user.cursor.setPosition(params.position);
      user.cursor.show();
    },

    newTextInsert(params) {
      this.userEditorContentManager.insert(params.index, params.text);
      this.userEditorContentManager.dispose();
    },

    newTextDelete(params) {
      this.userEditorContentManager.delete(params.index, params.length);
      this.userEditorContentManager.dispose();
    },

    onTab(params) {
      this.userEditor.setValue(params.text);
    },
  }
};
</script>

<style scoped>
	.output-result::before {
		content: '> ';
	}
	#editor-1::-webkit-scrollbar-thumb{
		display: none;
	}
	#editor-2::-webkit-scrollbar-thumb{
		display: none;
	}
</style>
