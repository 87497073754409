<template>
  <div v-lazy-container="{ selector: 'img' }">
    <div class="h-screen flex">
      <div class="h-full w-full mx-8">
        <slot name="content" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GameLayout'
};
</script>

<style scoped>
  .test {
      max-width: 100vw;
  }
  .sidebar-enter-active {
    animation: sidebar-anim .5s;
  }
  .sidebar-leave-active {
    animation: sidebar-anim .5s reverse;
  }
  @keyframes sidebar-anim {
    0% {
      transform: translateX(300px);
    }
    100% {
      transform: translateX(0);
    }
  }
</style>
