<template>
  <GameLayout>
    <!-- Content -->
    <template #content>
      <div class="w-full h-5/6 flex border-2 border-space-green rounded-xl px-6 py-6">
        <div class="w-4/6 pr-10">
          <h3 class="geminis text-space-green text-xl">
            Corrige le code
          </h3>
          <p class="text-sm my-5" v-if="currentExercise">
            {{ currentExercise.gameExercises.instruction }}
          </p>
          <div class="mb-6 text-sm">
            <span>Progression: {{ completedExercises }}/{{startedExercises.length}}</span>
            <div class="w-full bg-white rounded-full h-4 mb-4 mt-1">
              <div
                class="bg-space-green h-4 rounded-full"
                :style="'width: ' + (completedExercises / startedExercises.length) * 100 + '%'"
              />
            </div>
          </div>
          <div
            id="editor-1"
            class="h-2/6 lg:h-3/6 2xl:h-4/6 overflow-y-hidden"
            @click="onIdeClick"
          />
          <div class="w-full flex flex-col mt-2">
            <div class="w-full bg-space-dark h-20 px-4 py-2">
              <span>Console de sortie :</span><br>
              <span
                class="output-result"
                :class="output && output.isError ? 'text-red-500' : 'text-white'"
              >{{ output ? output.output : '' }}</span>
            </div>
            <div class="flex flex-row gap-x-8">
              <div
                class="text-center bg-white rounded-full font-bold px-4 py-1 text-space-dark-blue w-3/5 xl:w-1/5 mt-2 ml-2 cursor-pointer"
                @click="executeCode"
              >
                TESTER
              </div>
              <div
                class="text-center bg-white rounded-full font-bold px-4 py-1 text-space-dark-blue w-3/5 xl:w-1/5 mt-2 ml-2 cursor-pointer"
                @click="$socket.client.emit('onReset', {pin: pin, teamId: user.team.id})"
              >
                RESET
              </div>
            </div>
          </div>
        </div>
        <div class="w-2/6">
          <h3 class="geminis text-space-green text-xl">
            Ton adversaire: Player 2
          </h3>
          <div class="mb-2 text-sm">
            <span>Progression de ton adversaire: {{ completedOpponentExercises }}/{{opponentStartedExercises.length}}</span>
            <div class="w-full bg-white rounded-full h-4 mb-6 mt-1">
              <div
                class="bg-space-green h-4 rounded-full"
                :style="'width: ' +  (completedOpponentExercises / opponentStartedExercises.length) * 100 + '%'"
              />
            </div>
          </div>
          <div
            id="editor-2"
            class="h-2/6 overflow-y-hidden mt-4 w-full"
          />
          <div class="w-full flex justify-center mt-6" v-if="currentExercise">
            <ExerciseClues v-if="currentExercise.gameExercises && currentExercise.gameExercises.clues && currentExercise.gameExercises.clues.length" :clues="currentExercise.gameExercises.clues || []" />
            <img
              v-else
              :data-src="require('@/assets/images/games/astronaut.png')"
              alt="Astronaut"
              class="w-5/6 mt-6"
            >
          </div>
        </div>
      </div>
    </template>
  </GameLayout>
</template>

<script>
import GameLayout from '@/layouts/GameLayout';
import loader from "@monaco-editor/loader";
import GameService from "@/services/gameService";
import MonacoService from "../../services/monacoService";
import router, { ROUTE_ROOM_WIN, ROUTE_ROOM_LOSE } from "@/router";
import {store} from "@/store/store";
import {UPDATE_ROOM_STATE} from "@/store/modules/userModule/actions";
import {GAME_OVER} from "@/constants/games/GameStatusConstants";
import gameService from "../../services/gameService";
import ExerciseClues from "@/components/Games/ExerciseClues.vue";

export default {
  name: 'ErrorsVs',
  components: {
    ExerciseClues,
    GameLayout
  },
  props: {
    user: Object,
    room: Object,
  },
  data() {
    return {
      /** General */
      language: 'javascript',
      userEditor: null,
      opponentEditor: null,
      output: null,
      loading: false,
      pin: '',

      monaco: null,

      /** Game */
      team: null,
      opponentTeam: null,
      startedExercises: [],
      opponentStartedExercises: [],
      currentExercise: null,
      opponentCurrentExercise: null,

      /** Variables pour le partage des IDE */
      userRemoteCursorManager: null,
      opponentRemoteCursorManager: null,
      userEditorContentManager: null,
      opponentEditorContentManager: null,
    };
  },
  computed: {
    completedExercises() {
      return GameService.exercisesCompleted(this.startedExercises);
    },
    completedOpponentExercises() {
      return GameService.exercisesCompleted(this.opponentStartedExercises);
    }
  },
  watch: {
    currentExercise() {
      this.userEditor?.getModel().setValue(this.currentExercise ? this.currentExercise.code : '');
      this.monaco.editor.setModelLanguage(this.userEditor.getModel(), this.currentExercise ? this.currentExercise.gameExercises.language : 'javascript');
    },
    opponentCurrentExercise() {
      this.opponentEditor?.getModel().setValue(this.opponentCurrentExercise ? this.opponentCurrentExercise.code : '');
      this.monaco.editor.setModelLanguage(this.userEditor.getModel(), this.opponentCurrentExercise ? this.opponentCurrentExercise.gameExercises.language : 'javascript');
    },
    startedExercises() {
      if (!GameService.getNextNotSolvedExercise(this.startedExercises)) {
        gameService.changeWinStatus(this.room.game.id, this.user.id)
          .catch(({err}) => this.$toast.error(`games.game_over.${err.message}`));
        store.dispatch(UPDATE_ROOM_STATE, GAME_OVER);
        router.push({ name: ROUTE_ROOM_WIN.name, params: {pin: this.pin} });
      }
    },
    opponentStartedExercises() {
      if (!GameService.getNextNotSolvedExercise(this.opponentStartedExercises)) {
        store.dispatch(UPDATE_ROOM_STATE, GAME_OVER);
        router.push({ name: ROUTE_ROOM_LOSE.name, params: {pin: this.pin} });
      }
    }
  },
  async mounted() {
    document.title = 'Corrige le code | DotCode';
    this.pin = this.$route.params.pin;

    // Create Teams
    const teams = await GameService.getTeamsFromGameId(this.room.game.id);
    this.team = teams.find(team => team.id === this.user.team.id);
    this.opponentTeam = teams.find(team => team.id !== this.user.team.id);

    // Get exercises
    this.startedExercises = await GameService.getStartedExercicesTeamId(this.team.id, false);
    this.opponentStartedExercises = await GameService.getStartedExercicesTeamId(this.opponentTeam.id, true);

    // Init Monaco
    this.monaco = await loader.init();

    // Create editors
    this.userEditor = this.createEditor(document.getElementById("editor-1"));

    this.opponentEditor = this.createEditor(document.getElementById("editor-2"), {
      lineNumbers: false,
      scrollbar: false
    });

    // Next not solved exercise
    this.currentExercise = GameService.getNextNotSolvedExercise(this.startedExercises);
    this.opponentCurrentExercise = GameService.getNextNotSolvedExercise(this.opponentStartedExercises);

    // Cursor management
    this.userRemoteCursorManager = MonacoService.createRemoteCursorManager(this.userEditor);
    this.opponentRemoteCursorManager = MonacoService.createRemoteCursorManager(this.opponentEditor);

    // Create cursors
    this.team.users.forEach(user => {
      // Don't create for this user
      if (user.id === this.user.id) {
        return;
      }
      // Add cursor
      const newCursor = this.userRemoteCursorManager.addCursor(
        user.id.toString(),
        this.getRandomColor(),
        user.pseudo
      );

      // Add cursor to the user
      this.room.users.find(u => u.id === user.id ).cursor = newCursor;

      newCursor.setPosition({column: 3, lineNumber: 3});
      newCursor.show();
    });

    this.opponentTeam.users.forEach(user => {
      // Add cursor
      const newCursor = this.opponentRemoteCursorManager.addCursor(
        user.id.toString(),
        this.getRandomColor(),
        user.pseudo
      );
      newCursor.setPosition({column: 3, lineNumber: 3});
      newCursor.show();
      // Add to user
      this.room.users.find(u => u.id === user.id).cursor = newCursor;
    });

    // Editor content managers
    this.userEditorContentManager = MonacoService.createRemoteContentManager(
      this.userEditor,
      this.$socket,
      this.team.id,
      this.pin
    );
    this.opponentEditorContentManager = MonacoService.createOpponentRemoteContentManager(this.opponentEditor);

    // IDE on key up
    this.userEditor.onKeyUp((event) => MonacoService.onKeyUpListener(
      event,
      this.$socket,
      this.userEditor,
      this.pin,
      this.team.id,
      this.user
    ));
  },
  methods: {
    createEditor(elId, options) {
      return this.monaco.editor.create(elId, {
        ...options,
        value: '',
        language: this.language,
        theme: 'vs-dark',
        minimap: {enabled: false}
      });
    },

    async executeCode() {
      // Inform the other client that a validation is happening
      // this.$socket.client.emit('validating', {
      //   pin: this.$route.params.pin,
      //   validating: true
      // });

      if (!this.loading) {
        // Validate and save
        const res = await GameService.validateExercise(this.currentExercise, this.userEditor.getModel().getValue());

        this.output = {
          output: res.error ?? res.output,
          isError: !!res.error,
        };

        if (!res.error) {
          this.loading = true;

          // Next exercise
          setTimeout(async () => {
            this.$socket.client.emit('nextExercise', {
              pin: this.pin,
              team_id: this.team.id,
            });
            this.startedExercises = await GameService.getStartedExercicesTeamId(this.team.id, false);
            this.currentExercise = GameService.getNextNotSolvedExercise(this.startedExercises);
            this.output = null;
            this.loading = false;
          }, 1500);
        }
      }
    },

    onIdeClick() {
      MonacoService.onIdeClick(this.$socket, this.user, this.userEditor, this.pin);
    },

    getRandomColor() {
      return '#' + Math.floor(Math.random()*16777215).toString(16);
    },

    sendResetCode() {
      this.$socket.client.emit('onReset', {teamId: this.user.team.id});
    },

    resetCode(teamId) {
      if (this.user.team.id === teamId) {
        this.userEditor.getModel().setValue(this.currentExercise.code);
        return;
      }
      this.opponentEditor.getModel().setValue(this.opponentCurrentExercise.code);
    }
  },

  sockets: {
    validating(value) {
      this.loading = value;
    },

    async nextExercise(params) {
      // Your team
      if (params.team_id === this.team.id) {
        this.startedExercises = await GameService.getStartedExercicesTeamId(this.team.id, false);
        this.currentExercise = GameService.getNextNotSolvedExercise(this.startedExercises);
        // Opponent team
      } else {
        this.opponentStartedExercises = await GameService.getStartedExercicesTeamId(this.opponentTeam.id, true);
        this.opponentCurrentExercise = GameService.getNextNotSolvedExercise(this.opponentStartedExercises);
      }
    },

    userCursorChange(params) {
      const user = this.room.users.find(user => user.id === params.user.id);
      user.cursor.setPosition(params.position);
      user.cursor.show();
    },

    newTextInsert(params) {
      if (this.user.team.id === params.team_id) {
        this.userEditorContentManager.insert(params.index, params.text);
        this.userEditorContentManager.dispose();
      } else {
        this.opponentEditorContentManager.insert(params.index, params.text);
        this.opponentEditorContentManager.dispose();
      }
    },

    newTextDelete(params) {
      if (this.user.team.id === params.team_id) {
        this.userEditorContentManager.delete(params.index, params.length);
        this.userEditorContentManager.dispose();
      } else {
        this.opponentEditorContentManager.delete(params.index, params.length);
        this.opponentEditorContentManager.dispose();
      }
    },

    onTab(params) {
      if (this.user.team.id === params.team_id) {
        this.userEditor.getModel().setValue(params.text);
      } else {
        this.opponentEditor.getModel().setValue(params.text);
      }
    },

    onReset(param) {
      this.resetCode(param.teamId);
    }
  }
};
</script>

<style scoped>
	.output-result::before {
		content: '> ';
	}
	#editor-2 {
		filter: blur(3px);
    pointer-events: none;
	}
	#editor-1::-webkit-scrollbar-thumb{
		display: none;
	}
	#editor-2::-webkit-scrollbar-thumb{
		display: none;
	}
</style>
