import * as MonacoCollabExt from "@convergencelabs/monaco-collab-ext";

class MonacoService {
  /**
     * Create a new Cursor Manager
     * @param editor
     * @returns {RemoteCursorManager}
     */
  createRemoteCursorManager(editor) {
    return new MonacoCollabExt.RemoteCursorManager({
      editor: editor,
      tooltips: true,
      tooltipDuration: 9999,
      className: 'monaco-remote-cursor-test',
      tooltipClassName: 'monaco-remote-cursor-tooltip-test'
    });
  }

  /**
     * Create a new content remote manager
     * @param editor
     * @param socket
     * @param teamId
     * @param pin
     * @returns {EditorContentManager}
     */
  createRemoteContentManager(editor, socket, teamId, pin) {
    return new MonacoCollabExt.EditorContentManager({
      editor: editor,
      onInsert(index, text) {
        socket.client.emit('newTextInsert', {
          index: index,
          text: text,
          team_id: teamId,
          pin: pin
        });
      },
      onDelete(index, length) {
        socket.client.emit('newTextDelete', {
          index: index,
          length: length,
          team_id: teamId,
          pin: pin
        });
      }
    });
  }

  createOpponentRemoteContentManager(editor) {
    return new MonacoCollabExt.EditorContentManager({
      editor: editor,
    });
  }

  onKeyUpListener(event, socket, editor, pin, teamId, user) {
    if (event.code === 'Tab') {
      socket.client.emit('onTab', {
        text: editor.getValue(),
        team_id: teamId,
        pin: pin
      });
      return;
    }
    socket.client.emit('userCursorChange', {
      pin: pin,
      user: user,
      position: editor.getPosition()
    });
  }

  onIdeClick(socket, user, editor, pin) {
    socket.client.emit('userCursorChange', {
      pin: pin,
      user: user,
      position: editor.getPosition()
    });
  }
}

export default new MonacoService();
