<template>
  <div class="flex flex-col w-full space-y-4">
    <div class="flex justify-between w-full pb-4 border-b">
      <span class="text-2xl font-bold">{{ $t('exercises.clueTitle')}}</span>
      <div class="flex space-x-2">
        <button
          class="rounded-full bg-space-green text-space-dark-blue w-8 h-8"
          :class="currentClueIndex === 0 ? '!opacity-50 !cursor-not-allowed' : ''"
          @click="previousClue"
          :disabled="currentClueIndex === 0"
        >
          <i class="fas fa-chevron-left"></i>
        </button>
        <button
          class="rounded-full bg-space-green text-space-dark-blue w-8 h-8"
          :class="currentClueIndex === clues.length - 1 ? '!opacity-50 !cursor-not-allowed' : ''"
          @click="nextClue"
          :disabled="currentClueIndex === clues.length - 1"
        >
          <i class="fas fa-chevron-right"></i>
        </button>
      </div>
    </div>
    <div class="flex flex-col space-y-2">
      <div class="flex space-x-4">
        <span class="rounded-full font-bold flex items-center justify-center bg-space-green text-space-dark-blue w-8 h-8">
          {{ currentClueIndex + 1 }}
        </span>
        <span class="text-xl font-bold">
          {{  $t('exercises.clue') }} n°{{ currentClueIndex + 1 }}
        </span>
      </div>
      <span class="text-md text-justify">
        {{ clues[currentClueIndex] }}
      </span>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ExerciseClues',
  props: {
    clues: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  data() {
    return {
      currentClueIndex: 0
    };
  },
  methods: {
    nextClue() {
      this.currentClueIndex = this.currentClueIndex + 1;
    },
    previousClue() {
      this.currentClueIndex = this.currentClueIndex - 1;
    }
  }
};
</script>
