<template>
  <div>
    <div v-if="!room">
      <Loading />
    </div>
    <ErrorsVs
      v-else-if="room.game.type === 'vs'"
      :user="user"
      :room="room"
    />
    <ErrorsMulti
      v-else-if="room.game.type === 'multi'"
      :user="user"
      :room="room"
    />
  </div>
</template>

<script>
import ErrorsVs from '@/components/Games/ErrorsVs.vue';
import ErrorsMulti from '@/components/Games/ErrorsMulti.vue';
import {API} from "@/utils/api";
import Loading from "@/components/loading/Loading.vue";

export default {
  name: 'Game',
  components: {
    Loading,
    ErrorsVs,
    ErrorsMulti,
  },
  data() {
    return {
      user: null,
      room: null,
    };
  },
  async mounted() {
    // Get user
    const user = this.$store.getters.getUser;
    this.user = await API.get(`/user/${user.id}/me`)
      .then(res => res.data)
      .catch(() => this.$router.push({ name: 'home' }));

    // Get room
    this.room = await API.get(`/room`, {params: {pin: this.$route.params.pin}})
      .then(res => res.data)
      .catch(() => this.$router.push({ name: 'home' }));

    this.$socket.client.emit('joinRoom', {
      pin: this.room.pin
    });
  }
};
</script>
